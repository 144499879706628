import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import bannerBg from '../assets/images/banner-bg5.png';
import logo from '../assets/images/email-banner-logo.png';
import footerLogo from '../assets/images/footer-logo.png';
import instaIcon from '../assets/images/insta.svg';
import linkedIcon from '../assets/images/linkedin.svg';
import xIcon from '../assets/images/xicon.svg';
import fbIcon from '../assets/images/fb.svg';
import youtubeIcon from '../assets/images/youtube.svg';

function ResetPasswordEmail() {

    useEffect(() => {
      const link = document.createElement('link');
      link.href = 'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap';
      link.rel = 'stylesheet';
      const link2 = document.createElement('link');
      link2.href = 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap';
      link2.rel = 'stylesheet';
      document.head.appendChild(link);
      document.head.appendChild(link2);
      document.body.style.padding = '0';
      document.body.style.margin = '0';
      document.body.style.color = '#fff';
  
      return () => {
        document.head.removeChild(link);
        document.body.style.padding = '';
        document.body.style.margin = '';
        document.body.style.color = '';
      };
    }, []);
  
    return (
      <>
        <table style={{width: '100%',borderCollapse: 'collapse'}}>
          <tbody>
            <tr style={{padding:"0",margin:'0'}}>
                <td style={{padding:"0",margin:'0'}}>
                    <div 
                      className='template-banner-wrapper' 
                      style={{
                        width: '100%', 
                        height:'100vh', 
                        backgroundImage:`url(${bannerBg})`, 
                        backgroundSize: 'cover', 
                        backgroundPosition : 'center',
                        position:'relative',
                      }}>
                      <div 
                        className='welcome-banner-layer'
                        style={{
                          background:'rgba(0, 0, 0, 0.69)',
                          width:'100%',
                          height:'100%',
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center'
                        }}
                      >
                        <div 
                          className='welcome-banner-content'
                          style={{
                           textAlign:'center'
                          }}
                        >
                          <img src={logo} alt='logo'/>
                          <h2 
                            style={{
                              fontSize:"40px",
                              fontWeight:'400',
                              lineHeight:'50px',
                              fontFamily:'"Bebas Neue", sans-serif',
                              color: 'transparent',
                              WebkitTextStrokeWidth: '1px',
                              WebkitTextStrokeColor: 'white',
                            }}
                          >
                            AMPLIFYING CANADA'S FILM<br/>
                            TALENTS,ELEVATING  NEW VOICES
                          </h2>
                        </div>
                      </div>
                    </div>
                </td>
            </tr>
            <tr style={{padding:"0",margin:'0'}}>
              <td style={{padding:"0",margin:'0'}}>
                <div 
                  className='email-content'
                  style={{padding:'60px', backgroundColor:'#000'}}
                >
                  <div>
                    <h3
                      style={{
                        color:'#fff',
                        fontFamily: 'Roboto',
                        fontSize: '28px',
                        fontWeight: '400',
                        lineHeight: '35px',
                        textAlign: 'left',
                      }}
                    >
                        Reset Your Cinewoo Password
                    </h3>
                    <div style={{marginTop:'30px'}}>
                      <p 
                        style={{
                          color:'#fff',
                          fontSize:'16px',
                          fontWeight:'400',
                          lineHeight:'20px',
                          fontFamily:'"Roboto", sans-serif'
                        }}
                      >
                        Hi [First Name],
                      </p>
                      <br/>
                      <p 
                        style={{
                          color:'#fff',
                          fontSize:'16px',
                          fontWeight:'400',
                          lineHeight:'20px',
                          fontFamily:'"Roboto", sans-serif'
                        }}
                      >
                        We received a request to reset your password for your Cinewoo account. Click the link below to reset your password:
                      </p>
                      <br/>
                      <p
                        style={{
                            color:'rgba(251, 176, 59, 1)',
                            fontSize:'16px',
                            fontWeight:'400',
                            lineHeight:'20px',
                            fontFamily:'"Roboto", sans-serif'
                        }}
                      >
                        Reset Password Link
                    </p>
                      <p 
                        style={{
                          color:'#fff',
                          fontSize:'16px',
                          fontWeight:'400',
                          lineHeight:'20px',
                          fontFamily:'"Roboto", sans-serif'
                        }}
                      >
                        If you didn’t request a password reset, please ignore this email or contact us at
                        <br/><Link style={{color:'rgba(251, 176, 59, 1)'}}>support@cinewoo.com.</Link>
                      </p>
                      <br/>
                      <p 
                        style={{
                          color:'#fff',
                          fontSize:'16px',
                          fontWeight:'400',
                          lineHeight:'20px',
                          fontFamily:'"Roboto", sans-serif'
                        }}
                      >
                        Warm regards,<br/>
                        The Cinewoo Team
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr style={{padding:"0",margin:'0'}}>
              <td style={{padding:"0",margin:'0'}}>
                <div 
                  className='email-upper-footer'
                  style={{
                    borderTop:'2px solid rgba(251, 176, 59, 1)',
                    padding:'60px',
                    textAlign:'center',
                    display: 'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center',
                    background:"rgba(44, 44, 44, 1)"
                  }}
                >
                  <p
                    style={{
                      color:'#fff',
                      fontSize:'16px',
                      fontWeight:'400',
                      lineHeight:'20px',
                      fontFamily:'"Roboto", sans-serif'
                    }}
                  >
                    P.S. Don’t forget to follow us on social media for the<br/>latest updates and exclusive content! 
                  </p>
                  <div>
                    <Link to='' style={{marginRight:'15px'}}>
                      <img src={instaIcon} alt="social icon" />
                    </Link>
                    
                    <Link to='' style={{marginRight:'15px'}}>
                      <img src={linkedIcon} alt="social icon" />
                    </Link>
                    
                    <Link to='' style={{marginRight:'15px'}}>
                      <img src={fbIcon} alt="social icon" />
                    </Link>
                    
                    <Link to='' style={{marginRight:'15px'}}>
                      <img src={xIcon} alt="social icon" />
                    </Link>
                    
                    <Link to='' >
                      <img src={youtubeIcon} alt="social icon" />
                    </Link>
                  </div>
                </div>
              </td>
            </tr>
            <tr style={{padding:"0",margin:'0'}}>
              <td style={{padding:"0",margin:'0'}}>
                <div 
                  className='email-footer'
                  style={{
                    padding:'60px',
                    textAlign:'center',
                    display: 'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center',
                    background:"rgba(30, 30, 30, 1)"
                  }}
                >
                  <img src={footerLogo} alt="logo" />
                  <p
                    style={{
                      color:'#fff',
                      fontSize:'16px',
                      fontWeight:'400',
                      lineHeight:'20px',
                      fontFamily:'"Roboto", sans-serif'
                    }}
                  >
                    Copyright © 2024 CINEWOO STUDIOS Inc, All rights reserved.<br/>
                    You are receiving this email because you opted in via our website.
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }
  
  export default ResetPasswordEmail;