import React, { useEffect, useState } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import Spinner from '../components/loader/Spinner';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import SimpleVideoPlayer from '../components/SimpleVideoPlayer';

function UserFilmPitchingBooth() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [filmPitchings, setFilmPitchings] = useState([]);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const videoBaseUrl = process.env.REACT_APP_IMAGE_URL;
    const [loading, setLoading] = useState(false);

    const fetchFilmPitchings = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const language = localStorage.getItem("language");
            const response = await fetch(`${apiUrl}/filmpitching`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'accept-language': language
                },
            });
            const data = await response.json();
            if (response.ok) {
                setFilmPitchings(data.data);
                // console.log(data.data);
                setLoading(false);
            } else {
                console.error('Failed to fetch film pitchings:', data.message);
                if (data.message === 'Invalid token') {
                    navigate('/login');
                }
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching film pitchings:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFilmPitchings();
    }, [apiUrl]);


    const handleDeletePitchVideo = async (pitchId) => {
        fetchFilmPitchings();
        console.log(`Deleted video with id: ${pitchId}`);
    };

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar authorized={true} />

            <section className='h-100-v'>
                <CContainer>
                    <div className='page-wrapper position-relative pt-4 pb-3'>
                        <div className='page-heading-wrapper'>
                            <h2 className='page-heading'>{t('Pitching Box')}</h2>
                        </div>
                    </div>
                    <div className='user-film-booth-wrapper'>
                        <CRow>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <>
                                    {filmPitchings.map((film, index) => (
                                        <CCol key={index} lg={4} md={6}>
                                            <SimpleVideoPlayer
                                                title={`${film.pitch_user_by.first_name} ${film.pitch_user_by.sir_name}`} // Display the user's first and last name
                                                videoUrl={`${videoBaseUrl}/${film.pitch_video}`} // Full path to the video
                                                width='100%'
                                                height='100%'
                                                userProfile={true}
                                                btnContent={t('View')}
                                                profilelink={`/user-profile/${film.pitch_user_by._id}`}
                                                deleteId={true}
                                                videoId={film._id}
                                                videoType='Pitching Video'
                                                onDelete={handleDeletePitchVideo}
                                            />
                                        </CCol>
                                    ))}
                                    {filmPitchings.length === 0 && (
                                        <CCol lg={12}>
                                            <div className='text-white d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
                                                {t("No record Found!")}
                                            </div>
                                        </CCol>
                                    )}
                                </>
                            )}
                        </CRow>
                    </div>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default UserFilmPitchingBooth;
