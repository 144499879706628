import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol, CButton, CForm } from '@coreui/react';
import { CNav, CNavLink, CTabPane, CNavItem, CTabContent } from '@coreui/react';
import { CModal, CModalBody } from '@coreui/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

//-------- components
import MediaCard from './MediaCard';
import Spinner from './loader/Spinner';

function EditUserMedia(props) {
    const setBlocking = props.setBlocking;
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState(1);
    const userid = localStorage.getItem("userid");
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [loadingPhotos, setLoadingPhotos] = useState(false);
    const [loadingFilms, setLoadingFilms] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const [galleryPhotos, setGalleryPhotos] = useState([]);
    const [galleryFilms, setGalleryFilms] = useState([]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            setThumbnail(e.target.result);
        };
        reader.readAsDataURL(file);
        document.getElementById('thumbnailLabel').innerText = file.name;
    };

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const imgeUrl = process.env.REACT_APP_IMAGE_URL;
    const [filmTitle, setFilmTitle] = useState('');

    const handleAddFilm = async () => {
        props.setBlocking(true);
        setLoadingFilms(true);
        if (filmTitle === '' || thumbnail === null) {
            toast.error('Please Fill in All required fields!');
        } else {
            setVisible2(!visible2);
            try {
                const language = localStorage.getItem("language");
                const token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append('title', filmTitle);

                const thumbnailBlob = await fetch(thumbnail).then((res) => res.blob());
                formData.append('thumbnail', thumbnailBlob, 'thumbnail.jpg');

                const response = await fetch(`${apiUrl}/film`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'accept-language': language
                    },
                    body: formData
                });

                const responseData = await response.json();

                if (!response.ok) {
                    toast.error(responseData.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    props.setBlocking(false);
                }

                if (responseData.result === 'success') {
                    // toast.success(responseData.message);
                    toast.success(t('Film uploaded successfully!'));
                    props.setBlocking(false);
                    // fetchFilms();
                    setLoadingFilms(false);
                    const filmsObjects = responseData.data;
                    setGalleryFilms(prevFilms => [...prevFilms , filmsObjects]);
                }

            } catch (error) {
                console.error('Error creating film:', error.message);
                props.setBlocking(false);
            }
        }
        setLoadingFilms(false);
        props.setBlocking(false);
    };

    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImages(files);
        const photosElement = document.getElementById('photos_uploaded');
        photosElement.innerHTML = "";

        files.forEach(file => {
            const listItem = document.createElement('li');
            listItem.textContent = file.name;
            photosElement.appendChild(listItem);
        });
    };

    const handlePhotosUpload = async () => {
        props.setBlocking(true);
        setLoadingPhotos(true);
        const token = localStorage.getItem('token');
        const language = localStorage.getItem("language");

        const formData = new FormData();
        selectedImages.forEach((image) => {
            formData.append('photos', image);
        });

        if ([...formData.entries()].length === 0) {
            toast.error('Please browse at least one photo!');
        } else {
            setVisible(!visible);
            try {
                const response = await fetch(`${apiUrl}/photo`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'accept-language': language
                    },
                    body: formData,
                });

                const data = await response.json();
                if (response.ok) {
                    // toast.success(data.message);
                    toast.success(t('Photos uploaded successfully!'));
                    setSelectedImages([]);
                    const photoObjects = data.data;
                    setGalleryPhotos(prevPhotos => [ ...photoObjects]);
                    props.setBlocking(false);
                } else {
                    toast.error(data.message);
                    props.setBlocking(false);
                }
            } catch (error) {
                console.error('Error:', error);
                props.setBlocking(false);
            }
        }
        setLoadingPhotos(false);
        props.setBlocking(false);
    };

    const fetchPhotos = async () => {
        setLoadingPhotos(true);
        const language = localStorage.getItem("language");
        try {
            const response = await fetch(`${apiUrl}/photos`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'accept-language': language
                },
                body: JSON.stringify({
                    user_id: userid,
                }),
            });
            const data = await response.json();
            setGalleryPhotos(data.data);

            if (data.result === 'success') {
                // console.log(data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoadingPhotos(false);
    };

    useEffect(() => {
        fetchPhotos();
    }, []);


    const fetchFilms = async () => {
        setLoadingFilms(true);
        try {
            const language = localStorage.getItem("language");
            const response = await fetch(`${apiUrl}/films`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'accept-language': language
                },
                body: JSON.stringify({
                    user_id: userid,
                }),
            });

            const data = await response.json();
            setGalleryFilms(data.data);
            setLoadingFilms(false);

            if (data.result === 'success') {
                // console.log(data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoadingFilms(false);
    };

    useEffect(() => {
        fetchFilms();
    }, []);

    const handleDeletePhoto = async (photoId) => {
        fetchPhotos();
        console.log(`Deleted photo with id: ${photoId}`);
    };

    const handleDeleteFlim = async (photoId) => {
        fetchFilms();
        console.log(`Deleted film with id: ${photoId}`);
    };

    return (
        <>
            {/* <BlockUi tag="div" blocking={blocking}> */}
                <section className='media-wrapper'>
                    <CContainer>
                        <CRow>
                            <CCol lg={12}>
                                <div className='media-tabs'>
                                    <CNav variant="tabs" role="tablist">
                                        <CNavItem>
                                            <CNavLink
                                                href="#!"
                                                active={activeKey === 1}
                                                onClick={() => setActiveKey(1)}
                                            >
                                                {t('Photos')}
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink
                                                href="#!"
                                                active={activeKey === 2}
                                                onClick={() => setActiveKey(2)}
                                            >
                                                {t('Films')}
                                            </CNavLink>
                                        </CNavItem>
                                    </CNav>
                                    <CTabContent>
                                        <CTabPane role="tabpanel" aria-labelledby="photos-tab" visible={activeKey === 1}>
                                            <div className='media-tabs-wrapper'>
                                                <CRow>
                                                    {loadingPhotos ? (
                                                        <Spinner />
                                                    ) : galleryPhotos.length === 0 ? (
                                                        <div className='white-text p-5 w-100 text-center'>{t("No record available")}</div>
                                                    ) : (
                                                        <>
                                                            {galleryPhotos.map((photo, index) => (
                                                                <CCol key={index} lg={2} md={3} xs={6}>
                                                                    <MediaCard
                                                                        imgSrc={`${imgeUrl}/${photo.photo}`}
                                                                        mediaId={photo._id}
                                                                        clsname="photo-classname d-none"
                                                                        title="Photo Title"
                                                                        onDelete={handleDeletePhoto}
                                                                        mediaType="photo"
                                                                        editProfile={props.editProfile}
                                                                        setBlocking={setBlocking}
                                                                    />
                                                                </CCol>
                                                            ))}
                                                        </>
                                                    )}
                                                </CRow>
                                            </div>
                                            {galleryPhotos.length === 6?
                                                <></>
                                                :
                                                <div className='text-center upload-gallery py-5'>
                                                    <CButton className='primary_btn' onClick={() => setVisible(!visible)}>{t('Upload Photos')}</CButton>   
                                                </div>
                                            }
                                        </CTabPane>
                                        <CTabPane role="tabpanel" aria-labelledby="films-tab" visible={activeKey === 2}>
                                            {/* tab content for films */}
                                            <div className='media-tabs-wrapper'>
                                                <CRow>
                                                    {galleryFilms.length === 0 ? (
                                                        <div className='text-white p-5 w-100 text-center'>{t("No record available")}</div>
                                                    ) : (
                                                        galleryFilms.map((film, index) => (
                                                            <CCol key={index} lg={2} md={3} xs={6}>
                                                                <MediaCard
                                                                    imgSrc={`${imgeUrl}/${film.thumbnail}`}
                                                                    mediaId={film._id}
                                                                    clsname="film-classname d-block"
                                                                    title={film.title}
                                                                    onDelete={handleDeleteFlim}
                                                                    mediaType="film"
                                                                    editProfile={props.editProfile}
                                                                    setBlocking={setBlocking}
                                                                />
                                                            </CCol>
                                                        ))
                                                    )}
                                                </CRow>
                                            </div>
                                            {galleryFilms.length == 6 ?
                                                <></>
                                                :
                                                <div className='text-center upload-gallery py-5'>
                                                    <CButton className='primary_btn' onClick={() => setVisible2(!visible2)}>{t('Upload Film Stills')}</CButton>
                                                </div>
                                            }
                                        </CTabPane>
                                    </CTabContent>
                                </div>
                            </CCol>
                        </CRow>
                    </CContainer>
                </section>

                {/* modal to upload photos */}
                <CModal
                    className='photos-upload-modal'
                    alignment="center"
                    visible={visible}
                    onClose={() => setVisible(false)}
                    aria-labelledby="VerticallyCenteredExample"
                    size='lg'
                >
                    <CModalBody>
                        <div className='upload-photo-modal-body-content text-center'>
                            <h3>{t('Browse or DROP HERE TO UPLOAD thumbnails')}</h3>
                            <span className='file-restriction-txt'>{t('To upload file size is (Max 5mb) and maximum dimensions are 300X300')}</span>
                            <CForm>
                                <div className='text-center mt-4'>
                                    <label htmlFor='photoInput' className='secondary_outline_btn'>
                                        {t('Browse')}
                                    </label>
                                    <input
                                        type='file'
                                        id="photoInput"
                                        multiple
                                        onChange={handleImageChange}
                                        hidden
                                    />
                                    <ul id='photos_uploaded'></ul>
                                </div>
                                <div className='mt-4'>
                                    <CButton
                                        className='primary_btn'
                                        onClick={handlePhotosUpload}
                                    >
                                        {t('Save')}
                                    </CButton>
                                </div>
                            </CForm>
                        </div>
                        <CButton className='close_modal_btn' onClick={() => setVisible(false)}></CButton>
                    </CModalBody>
                </CModal>

                {/* modal to upload films */}
                <CModal
                    className='photos-upload-modal'
                    alignment="center"
                    visible={visible2}
                    onClose={() => setVisible2(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalBody>
                        <div className='upload-photo-modal-body-content film-upload-content text-center'>
                            <h3>{t('Add film')}</h3>
                            <CForm>
                                <div className='w-100 d-flex'>
                                    <div className='w-50 p-2'>
                                        <input
                                            type='text'
                                            placeholder={`${t('Film Title')}*`}
                                            value={filmTitle}
                                            onChange={(e) => setFilmTitle(e.target.value)}
                                        />
                                    </div>

                                    <div className='w-50 p-2'>
                                        <label htmlFor='thumbnail'>{t('Upload Thumbnail')}*</label>
                                        <input
                                            id='thumbnail'
                                            type='file'
                                            placeholder={`${t('Upload Thumbnail')}*`}
                                            hidden
                                            accept='image/png, image/jpeg, image/jpg'
                                            onChange={handleFileChange}
                                        />
                                        <p id='thumbnailLabel'></p>
                                    </div>
                                </div>
                                <div className='w-100 d-flex'>
                                    <div className='w-50 mt-4 p-2'>
                                        <CButton className='secondary_outline_btn w-100' onClick={() => setVisible(!visible2)}>
                                            {t('Cancel')}
                                        </CButton>
                                    </div>
                                    <div className='w-50 mt-4 p-2'>
                                        <CButton className='primary_btn w-100' onClick={()=>handleAddFilm()} style={{ marginTop:'15px' }}>
                                            {t('Save')}
                                        </CButton>
                                    </div>
                                </div>
                            </CForm>
                        </div>
                        <CButton className='close_modal_btn' onClick={() => setVisible2(false)}></CButton>
                    </CModalBody>
                </CModal>
            {/* </BlockUi> */}
        </>
    );
}

export default EditUserMedia;
