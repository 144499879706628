import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { CFormInput, CForm, CFormLabel, CButton, CFormCheck } from '@coreui/react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import Spinner from './loader/Spinner';
import './loader/Spinner.css';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import AppleLogin from 'react-apple-login'; 
import { gapi } from 'gapi-script';
import { useGoogleLogin } from 'react-google-login';

// images
import logo from '../assets/images/logo.png';
import MultiStepProgressBar from '../components/MultiStepProgressBar';

function SignUp() {

    const [blocking, setBlocking] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/');
    }

    //*//----- from stepper states -----//*//
    const [formStep, setFormStep] = useState(1);
    const [nameError, setNameError] = useState(false);
    const [error1, setError1] = useState(false);
    const [languageError, setLanguageError] = useState(false);
    const [error2, setError2] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [error3, setError3] = useState(false);
    const [error4, setError4] = useState(false);
    const [dobVisible, setDobVisible] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const production = process.env.REACT_APP_ENV;

    // Set the redirect URL based on the environment
    const redirectUrl = production === 'production' ? 'https://cinewoo.com' : 'https://frontend.cinewoo.com';

    // for google login
    useEffect(() => {
        function start() {
        gapi.client.init({
            clientId: "753595080102-h6sj78cc13i0oe0h4hulnb3bs5r5rqj6.apps.googleusercontent.com",
            scope: "email",
        });
        }
        gapi.load("client:auth2", start);
    }, []);

    const responseGoogle = (response) => {
        setBlocking(true);
        if (response.error) {
          console.error("Google Login Error:", response.error);
          toast.error("Google Login Error: " + response.error);
          setBlocking(false);
          return;
        }
    
        const { profileObj } = response;
        const body = {
          name: profileObj.name,
          email: profileObj.email,
          provider: 'gmail',
          provider_id: profileObj.googleId,
        };
    
        fetch(`${apiUrl}/social-login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((res) => {
            if (!res.ok) {
              setBlocking(false);
              throw new Error("Network response was not ok " + res.statusText);
            }
            return res.json();
          })
          .then((data) => {
            const userId = data.data.id;
            const tokens = data.data.token;
            if (userId) {
              localStorage.setItem("userid", userId);
              toast.success("User logged in successfully!");
              localStorage.setItem('token', tokens);
              setBlocking(false);
              navigate("/");
            } else {
              setBlocking(false);
              console.error("Google login failed");
            }
          })
          .catch((error) => {
            setBlocking(false);
            toast.error("Google login failed: " + error.message);
          });
      };
    
      // for apple login
      const responseApple = async (response) => {
        console.log('apple response');
        console.log('apple response :',response);
        setBlocking(true);
        try {
          if (response.error) {
            console.error("Apple Login Error:", response.error.error);
            toast.error("Apple Login Error: " + response.error.error);
            setBlocking(false);
            return;
          }
    
          const id_token = response.authorization.id_token;
          
          console.log('id_token : ', response.authorization );
          const body = {
            // name: user?.name?.firstName + ' ' + user?.name?.lastName,
            // email: email,
            provider: 'apple',
            provider_id: id_token,
          };
    
          console.log('body data: ',body);
    
          const res = await axios.post(`${apiUrl}/social-login`, body, {
            headers: {
              "Content-Type": "application/json",
            }
          });
    
          const data = res.data;
          const userId = data.data.id;
          const tokens = data.data.token;
    
          if (userId) {
            localStorage.setItem("userid", userId);
            toast.success("User logged in successfully!");
            localStorage.setItem('token', tokens);
            setBlocking(false);
            navigate("/settings");
          } else {
            setBlocking(false);
            console.error("Apple login failed");
          }
        } catch (error) {
          setBlocking(false);
          toast.error("Apple login failed: " + error.response.data.message);
        }
      };
    
      const { signIn } = useGoogleLogin({
        onSuccess: responseGoogle,
        onFailure: responseGoogle,
        clientId: "753595080102-h6sj78cc13i0oe0h4hulnb3bs5r5rqj6.apps.googleusercontent.com",
        scope: "email",
      });

    const handleCheckboxChange = (event) => {
        setPrivacyPolicy(event.target.checked);
    };

    const handleNextStep = () => {   
        if (formStep < 3 && firstName.trim() !== '' && lastName.trim() !== '') {
            setFormStep(formStep + 1);
            setError1(false);
            setNameError(false);
        }else if(formStep < 3 && firstName.trim() === ''){
            setNameError(true);
        }else if(formStep < 3 && lastName.trim() === ''){
            setError1(true);
            setNameError(false);
        }
    }

    const handleNextStep2 = () => {
        if (formStep < 3 && language.trim() !== '' && industry.trim() !== '') {
            setFormStep(formStep + 1);
            setLanguageError(false);
            setError2(false);
        }else if(formStep < 3 && language.trim() === ''){
            setLanguageError(true);
        }else if(formStep < 3 && industry.trim() === ''){
            setError2(true);
            setLanguageError(false);
        }
    }

    const handleBackStep = () => {
        if(formStep > 1){
            setFormStep(formStep - 1);
        }
    }

    const [formStepComplete, setFormStepComplete] = useState(false);

    //------- start date picker
    const currentDate = new Date();
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const [isDobModified, setIsDobModified] = useState(false);

    // Function to get the maximum number of days in a month
    const getDaysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const handleDayChange = (e) => {
        const day = parseInt(e.target.value);
        setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day));
        setIsDobModified(true);
    };

    const handleMonthChange = (e) => {
        const month = parseInt(e.target.value);
        const maxDays = getDaysInMonth(month, selectedDate.getFullYear());
        const newDay = selectedDate.getDate() <= maxDays ? selectedDate.getDate() : maxDays;
        setSelectedDate(new Date(selectedDate.getFullYear(), month, newDay));
        setIsDobModified(true);
    };

    const handleYearChange = (e) => {
        const year = parseInt(e.target.value);
        const maxDays = getDaysInMonth(selectedDate.getMonth(), year);
        const newDay = selectedDate.getDate() <= maxDays ? selectedDate.getDate() : maxDays;
        setSelectedDate(new Date(year, selectedDate.getMonth(), newDay));
        setIsDobModified(true);
    };

    const renderDayOptions = () => {
        const maxDays = getDaysInMonth(selectedDate.getMonth(), selectedDate.getFullYear());
        return [...Array(maxDays)].map((_, index) => (
            <option key={index + 1} value={index + 1}>{index + 1}</option>
        ));
    };

    //---------end date picker
    //------ Register API Integration
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userDob, setUserDob] = useState('');
    const [email, setEmail] = useState('');
    const [language, setLanguage] = useState('');
    const [industry, setIndustry] = useState('');
    const [otherIndustry, setOtherIndustry] = useState('');
    const [password, setPasword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    const handleUserDob = () => {
        setDobVisible(true);
        console.log("user dob : ", userDob);
    }

    const handlePassowordChange = (e)=>{
        setConfirmPassword(e.target.value);
        // if (password !== confirmPassword) {
        //     setError4(true);
        // }else{
        //     setError4(false);
        // }
    }

    const handleRegister = async () => {
        if(password.trim() !== '' && confirmPassword.trim() !== '' && email.trim() !== ''){
            if(password !== confirmPassword){
                // Passwords do not match
                setError4(true);
                setError3(false);
            }else{
                setError4(false);
                setLoading(true);
                setBlocking(true);
                try {
                    const userData = {
                        first_name: firstName,
                        sir_name: lastName,
                        // date_of_birth: selectedDate,
                        language: language,
                        industry: industry,
                        other_industry: otherIndustry,
                        email: email,
                        password: password,
                        confirm_password: confirmPassword
                    };
                    if (isDobModified) {
                        userData.date_of_birth = selectedDate;
                    }
                    // console.log(userData);
                    const response = await axios.post(`${apiUrl}/register`, userData);
            
                    // console.log(response.data);
                    if (response.data.result === 'success') {
                        setBlocking(false);
                        setLoading(false);
                        localStorage.setItem('token',response.data.data.token);
                        localStorage.setItem('userid',response.data.data.id);
                        localStorage.setItem('user', JSON.stringify(response.data.data));
                        const userLanguage = response.data.data.language;
                        console.log('selected language : ', userLanguage);
                        if (userLanguage === 'english') {
                            localStorage.setItem('language','en');
                        }else if(userLanguage === 'french'){
                            localStorage.setItem('language','fr');
                        }
                        
                        // Handle success
                        console.log('Signup successful');
                        setFormStepComplete(true);
                        setFormStep(0);
                        setError3(false);
                        toast.success(t('Signup successful!'));
                    } else {
                        // Handle error
                        setBlocking(false);
                        setLoading(false);
                        console.error('Signup failed:', response.data.message);
                        toast.error(response.data.message);
                    }

                    if(response.data.result === 'error'){
                        toast.error(response.data.message);
                        setLoading(false);
                        setBlocking(false);
                    }

                } catch (error) {
                    setLoading(false);
                    setBlocking(false);
                    console.error('Error signing up:',  error.message);
                    toast.error(error.response.data.message);
                }
            }
        }else if(email.trim() === ''){
            setEmailError(true);
        }else{
            setError3(true);
            setEmailError(false);
        }
    };

    return (
        <>
            <BlockUi tag="div" blocking={blocking}>
                {/* <!-- Auth Section -- Start Here --> */}
                <section className={`auth-section-wrapper ${formStep === 0 ? 'h-100-vh' : ''}`}>
                    <CContainer className="h-100-p">
                        <CRow className="align-items-center h-100-p">
                            <CCol md="7">
                                <div className='site-logo'>
                                    <Link to='/'>
                                        <img src={logo} alt='logo' />
                                    </Link>
                                </div>
                            </CCol>
                            <CCol md="5">
                                {formStepComplete ? 
                                    <div className="auth-form-wrapper">
                                        <div className='confirmation-msg-wraper w-100'>
                                            <h2 className="animate-heading">{t('Congratulation')}</h2>
                                            <p className='my-5'>{t('Confirmation email has been')}<br/>{t('sent your email')} </p>
                                        </div>
                                        <div className="login-btn-wrapper w-100">
                                            <CButton onClick={handleNavigate}>{t('Back to Home page')}</CButton>
                                        </div>
                                    </div>
                                    :
                                
                                    <div className="auth-form-wrapper">
                                        <div className="app-logo-wrapper">
                                            <h2>{t('Sign Up')}</h2>
                                        </div>

                                        {/*/--- progress bar -- Start Here ---/*/}
                                        <div className='progressBar-wrapper'>
                                            <MultiStepProgressBar step={formStep} />
                                        </div>
                                        {/*/--- progress bar -- End Here ---/*/}

                                        {formStep === 1 ?
                                        <>
                                            {/*/ first step form -- Start Here /*/}
                                            <div className="auth-form-wrapper-content">
                                                <CForm>
                                                    <div className="single-input-wrapper">
                                                        <CFormLabel  className="d-block">{t('First Name')}</CFormLabel >
                                                        <CFormInput 
                                                            type="text" 
                                                            name="" 
                                                            placeholder={t('Name')}
                                                            value={firstName} 
                                                            onChange={(e) => setFirstName(e.target.value)} 
                                                        />
                                                        {nameError ?
                                                            <p className='error-msg'>{t('Please fill in all required fields')}!</p>
                                                        :
                                                        <></>
                                                        }
                                                    </div>
                                                    <div className="single-input-wrapper mt-4">
                                                        <CFormLabel className="d-block">{t('Surname')}</CFormLabel >
                                                        <CFormInput 
                                                            type="text" 
                                                            name="" 
                                                            placeholder={t('Surname')}
                                                            value={lastName} 
                                                            onChange={(e) => setLastName(e.target.value)}
                                                        />
                                                        {error1?
                                                        <p className='error-msg'>{t('Please fill in all required fields')}!</p>
                                                        :
                                                        <span></span>
                                                        }
                                                    </div>

                                                    {/*/--- date of birth ---/*/}
                                                    <div className='signup-next-btn-wrapper'>
                                                        <CButton className='signup-next-btn step-two-next-btn dob-selector' onClick={handleUserDob}>{t('Select DOB')}</CButton>
                                                    </div>
                                                    {dobVisible?
                                                        <div className="single-input-wrapper mt-4">
                                                            <label className="d-block">{t('Date of Birth (Optional)')}</label>
                                                            <div className='dob-input-wrapper'>
                                                                <div className='dob-input-content'>
                                                                    <label className="d-block">{t('Month')}</label>
                                                                    <select onChange={handleMonthChange} value={selectedDate.getMonth()}>
                                                                        {[...Array(12)].map((_, index) => (
                                                                            <option key={index} value={index}>{new Date(0, index).toLocaleString('default', { month: 'long' })}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className='dob-input-content'>
                                                                    <label className="d-block">{t('Day')}</label>
                                                                    <select onChange={handleDayChange} value={selectedDate.getDate()}>
                                                                        {renderDayOptions()}
                                                                    </select>
                                                                </div>
                                                                <div className='dob-input-content'>
                                                                    <label className="d-block">{t('Year')}</label>
                                                                    <select onChange={handleYearChange} value={selectedDate.getFullYear()}>
                                                                        {[...Array(200)].map((_, index) => (
                                                                            <option key={index} value={currentDate.getFullYear() - index}>{currentDate.getFullYear() - index}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                    {/*/--- date of birth -- end's here ---/*/}
                                                    <div className='signup-next-btn-wrapper'>
                                                        <CButton className='signup-next-btn step-two-next-btn' onClick={handleNextStep}>{t('Next')}</CButton>
                                                    </div>
                                                    <div className='register-link-wrapper mt-4'>
                                                        <p>{t('Already have an account')} ? <Link to='/login'>{t('Login')}</Link></p>
                                                    </div>
                                                </CForm>
                                            </div>

                                            <div className="auth-footer-wrapper mb-5">
                                                <div className='social-login'>
                                                    <p>{t('OR')}</p>
                                                    <p>{t('Login with')}</p>
                                                </div>
                                                <div className='social-login-links'>
                                                    {/* <Link to='' className='me-5'>
                                                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_2014_5033)">
                                                                <path d="M30.2483 0C27.9026 0.162249 25.1608 1.66374 23.5631 3.61898C22.1056 5.39272 20.9066 8.0272 21.3741 10.5874C23.9371 10.6672 26.5853 9.12995 28.1198 7.14171C29.5553 5.29097 30.6416 2.67298 30.2483 0Z" fill="#E3E3E3"/>
                                                                <path d="M39.5182 14.7621C37.266 11.9378 34.1008 10.2988 31.1116 10.2988C27.1653 10.2988 25.4961 12.1881 22.7543 12.1881C19.9274 12.1881 17.7796 10.3043 14.3669 10.3043C11.0147 10.3043 7.44519 12.3531 5.18195 15.8565C2.00022 20.79 2.54472 30.0657 7.70094 37.9664C9.54618 40.7934 12.0102 43.9724 15.2331 43.9999C18.1014 44.0274 18.9099 42.1601 22.7956 42.1409C26.6813 42.1189 27.4183 44.0246 30.2811 43.9944C33.5068 43.9696 36.1055 40.4469 37.9508 37.6199C39.2735 35.5932 39.7658 34.5729 40.7915 32.285C33.3308 29.4442 32.1345 18.8348 39.5182 14.7621Z" fill="#E3E3E3"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_2014_5033">
                                                                    <rect width="44" height="44" fill="white"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Link> */}

                                                    {/*/--- Apple Login ---/*/}
                                                    <AppleLogin
                                                        clientId=  {clientId}
                                                        redirectURI= {redirectUrl}
                                                        usePopup={true}
                                                        callback={responseApple}
                                                        scope="email name"
                                                        responseType="id_token"
                                                        responseMode="query"
                                                        render={(renderProps) => (
                                                            <Link onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_2014_5033)">
                                                                    <path d="M30.2483 0C27.9026 0.162249 25.1608 1.66374 23.5631 3.61898C22.1056 5.39272 20.9066 8.0272 21.3741 10.5874C23.9371 10.6672 26.5853 9.12995 28.1198 7.14171C29.5553 5.29097 30.6416 2.67298 30.2483 0Z" fill="#E3E3E3"/>
                                                                    <path d="M39.5182 14.7621C37.266 11.9378 34.1008 10.2988 31.1116 10.2988C27.1653 10.2988 25.4961 12.1881 22.7543 12.1881C19.9274 12.1881 17.7796 10.3043 14.3669 10.3043C11.0147 10.3043 7.44519 12.3531 5.18195 15.8565C2.00022 20.79 2.54472 30.0657 7.70094 37.9664C9.54618 40.7934 12.0102 43.9724 15.2331 43.9999C18.1014 44.0274 18.9099 42.1601 22.7956 42.1409C26.6813 42.1189 27.4183 44.0246 30.2811 43.9944C33.5068 43.9696 36.1055 40.4469 37.9508 37.6199C39.2735 35.5932 39.7658 34.5729 40.7915 32.285C33.3308 29.4442 32.1345 18.8348 39.5182 14.7621Z" fill="#E3E3E3"/>
                                                                    </g>
                                                                    <defs>
                                                                    <clipPath id="clip0_2014_5033">
                                                                        <rect width="44" height="44" fill="white"/>
                                                                    </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </Link>
                                                        )}
                                                    />

                                                    <Link onClick={signIn} className='ms-5'>
                                                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g style={{mixBlendMode:'luminosity'}} clipPath="url(#clip0_2014_5040)">
                                                                <path d="M36.1537 38.7321C32.3381 41.9548 27.3882 44.0001 21.9998 44.0001C13.9818 44.0001 6.94347 39.5829 3.12793 33.1376L4.55638 26.5529L10.8881 25.3774C12.3576 30.1211 16.7919 33.6017 21.9998 33.6017C24.5263 33.6017 26.8725 32.8024 28.8061 31.3845L34.8904 32.3126L36.1537 38.7321Z" fill="#59C36A"/>
                                                                <path d="M36.1539 38.7319L34.8906 32.3124L28.8063 31.3843C26.8727 32.8022 24.5265 33.6015 22 33.6015V43.9999C27.3884 43.9999 32.3383 41.9546 36.1539 38.7319Z" fill="#00A66C"/>
                                                                <path d="M10.3984 21.9998C10.3984 23.1857 10.5789 24.32 10.8883 25.3771L3.12813 33.1373C1.19453 29.8889 0 26.0732 0 21.9998C0 17.9263 1.19453 14.1107 3.12813 10.8623L9.35627 11.9342L10.8883 18.6225C10.5789 19.6794 10.3984 20.8138 10.3984 21.9998Z" fill="#FFDA2D"/>
                                                                <path d="M44 22C44 28.703 40.8977 34.7102 36.1539 38.732L28.8063 31.3844C30.3015 30.3015 31.5648 28.8577 32.3898 27.1562H22C21.278 27.1562 20.7109 26.589 20.7109 25.8672V18.1328C20.7109 17.4109 21.278 16.8438 22 16.8438H42.35C42.9687 16.8438 43.5102 17.2819 43.6133 17.9007C43.8711 19.2414 44 20.6335 44 22Z" fill="#4086F4"/>
                                                                <path d="M32.3899 27.1562C31.5649 28.8577 30.3015 30.3015 28.8063 31.3844L36.1539 38.732C40.8977 34.7102 44 28.7031 44 22C44 20.6335 43.8711 19.2414 43.6132 17.9007C43.5102 17.2819 42.9687 16.8438 42.3499 16.8438H22V27.1562H32.3899Z" fill="#4175DF"/>
                                                                <path d="M36.5146 6.14445C36.5404 6.50538 36.3857 6.84062 36.1537 7.09835L30.6365 12.5898C30.1983 13.0538 29.4765 13.1054 28.9608 12.7187C26.9239 11.1977 24.5263 10.3984 21.9998 10.3984C16.7919 10.3984 12.3576 13.8788 10.8881 18.6227L3.12793 10.8625C6.94347 4.41719 13.9818 0 21.9998 0C27.1303 0 32.1318 1.89062 36.0506 5.19054C36.3342 5.42266 36.4889 5.78351 36.5146 6.14445Z" fill="#FF641A"/>
                                                                <path d="M28.9609 12.7187C29.4766 13.1055 30.1984 13.0538 30.6366 12.5898L36.1538 7.09835C36.3859 6.84062 36.5406 6.50547 36.5148 6.14445C36.4889 5.78342 36.3343 5.42266 36.0507 5.19054C32.132 1.89062 27.1305 0 22 0V10.3984C24.5265 10.3984 26.9241 11.1977 28.9609 12.7187Z" fill="#F03800"/>
                                                            </g>
                                                            <defs>
                                                            <clipPath id="clip0_2014_5040">
                                                                <rect width="44" height="44" fill="white"/>
                                                            </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                        :formStep === 2 ?
                                        <>
                                            {/*/ second step form -- Start Here /*/}
                                            <div className="auth-form-wrapper pb-5">
                                                <CForm>
                                                    <div className="single-input-wrapper">
                                                        <CFormLabel  className="d-block">{t('Language')}</CFormLabel >
                                                        <div className='language-input-wrapper'>
                                                            <CFormCheck 
                                                                type="radio" 
                                                                name="flexRadio" 
                                                                id="flexRadioDisabled" 
                                                                label={t('English')} 
                                                                className='mb-4'         
                                                                checked={language === 'english'}
                                                                onChange={() => setLanguage('english')}
                                                            />
                                                            <CFormCheck 
                                                                type="radio" 
                                                                name="flexRadio" 
                                                                id="flexRadioChecked" 
                                                                label={t('French')}
                                                                checked={language === 'french'}
                                                                onChange={() => setLanguage('french')}
                                                            />
                                                        </div>
                                                    </div>
                                                    {languageError?
                                                        <p className='error-msg'>{t('Please select an option')}!</p>
                                                    :
                                                        <></>
                                                    }
                                                    <div className="single-input-wrapper mt-2">
                                                        <CFormLabel  className="d-block">{t('Industry')}</CFormLabel >
                                                        <div className='language-input-wrapper'>
                                                            <CFormCheck 
                                                                type="radio" 
                                                                name="flexRadio2" 
                                                                id="flexRadioFilm" 
                                                                label={t('Film')}
                                                                className='mb-4'
                                                                value={'film'}
                                                                checked={industry === 'film'}
                                                                onChange={()=>setIndustry('film')}
                                                            />
                                                            <CFormCheck 
                                                                type="radio" 
                                                                name="flexRadio2" 
                                                                id="flexRadioTV" 
                                                                label={t('TV')}
                                                                className='mb-4' 
                                                                value={'TV'}
                                                                checked={industry === 'TV'}
                                                                onChange={()=>setIndustry('TV')}
                                                            />
                                                            <CFormCheck 
                                                                type="radio" 
                                                                name="flexRadio2" 
                                                                id="flexRadioOther" 
                                                                label={t('Other')}
                                                                value={'Other'}
                                                                checked={industry === 'Other'}
                                                                onChange={()=>setIndustry('Other')}
                                                            />
                                                        </div>
                                                        {industry === 'Other' && (
                                                            <div className='other-industry-wrapper'>
                                                                <CFormInput 
                                                                    type="text" 
                                                                    name="" 
                                                                    placeholder={t('Type your industry')} 
                                                                    value={otherIndustry} 
                                                                    onChange={(e) => setOtherIndustry(e.target.value)}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {error2?
                                                        <p className='error-msg'>{t('Please select an option')}!</p>
                                                    :
                                                        <></>
                                                    }

                                                    
                                                    <div className='signup-next-btn-wrapper d-flex justify-content-between'>
                                                        <CButton className='signup-next-btn' onClick={handleBackStep}>{t('Back')}</CButton>
                                                        <CButton className='signup-next-btn' onClick={handleNextStep2}>{t('Next')}</CButton>
                                                    </div>

                                                    <div className='register-link-wrapper mt-4'>
                                                        <p>{t('Already have an account')} ? <Link to='/login'>{t('Login')}</Link></p>
                                                    </div>
                                                </CForm>
                                            </div>
                                        </>
                                        :formStep === 3 ?
                                        <>
                                            {/*/ third step form -- Start Here /*/}
                                            <div className="auth-form-wrapper pb-5">
                                                <CForm>
                                                    <div className="single-input-wrapper">
                                                        <CFormInput 
                                                            type="email" 
                                                            name="" 
                                                            placeholder={t('Email')} 
                                                            value={email} 
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                    {emailError?
                                                        <p className='error-msg'>{t('Please fill in all required fields')}!</p>
                                                    :
                                                        <></>
                                                    }
                                                    <div className="single-input-wrapper mt-4">
                                                        <CFormInput 
                                                            type="password" 
                                                            name="" 
                                                            placeholder={t('Password')}
                                                            value={password} 
                                                            onChange={(e) => setPasword(e.target.value)}
                                                        />
                                                    </div>
                                                    {error3?
                                                        <p className='error-msg'>{t('Please fill in all required fields')}!</p>
                                                    :
                                                        <></>
                                                    }
                                                    <div className="single-input-wrapper mt-4">
                                                        {error4?
                                                            <p className='error-msg'>{t('Password does not match')}!</p>
                                                            :
                                                            <></>
                                                        }
                                                        <CFormInput 
                                                            type="password" 
                                                            name="" 
                                                            placeholder={t('Confirm Password')}
                                                            value={confirmPassword} 
                                                            onChange={(e) => handlePassowordChange(e)}
                                                        />
                                                    </div>
                                                    {error3?
                                                        <p className='error-msg'>{t('Please fill in all required fields')}!</p>
                                                    :
                                                        <></>
                                                    }

                                                    <div className="single-check-input-wrapper mt-4">

                                                        <div className="form-check">
                                                            <input 
                                                                type="checkbox" 
                                                                className="form-check-input" 
                                                                id="flexCheckDefault"
                                                                checked={privacyPolicy}
                                                                onChange={(e) => handleCheckboxChange(e)}
                                                            />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault" >{t('I agree to the')} <Link to='/terms-of-use' target='_blank'>{t('terms of use')}</Link> {t('and')} <Link to='/privacy-policy' target='_blank'>{t('privacy policy')}</Link>.</label>
                                                        </div>
                                                    </div>

                                                    <div className="login-btn-wrapper mt-5">
                                                        <CButton className='primary_btn' onClick={handleRegister} disabled={!privacyPolicy || loading}>
                                                            {loading?
                                                                <Spinner/>
                                                            :
                                                                <>
                                                                    {t('Submit')}
                                                                </>
                                                            }
                                                        </CButton>
                                                    </div>

                                                    <div className='register-link-wrapper mt-4'>
                                                        <p>{t('Already have an account')} ? <Link to='/login'>{t('Login')}</Link></p>
                                                    </div>
                                                </CForm>
                                            </div>
                                        </>
                                        :
                                        <></>
                                        }
                                    </div>
                                }
                            </CCol>
                        </CRow>
                    </CContainer>

                </section>
                {/* <!-- Auth Section -- End's Here --> */}
            </BlockUi>
           
        </>
    )
}

export default SignUp;
